$(document).ready(function(){

  $('.m-menu').click(function(){
    $('html, body').toggleClass('noscroll');
    $('body').toggleClass('m-menu-open');
  });

  if($('body').hasClass('home')){
    handleResize();
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);
  }

  // scroll stuff
  var scrolled = 0;
  var scrollOffset = 200;

  function handleScroll () {
    scrolled = window.scrollY;
    if(scrolled > scrollOffset){
      document.querySelector('body').classList.add('scrolling');
    }else{
      document.querySelector('body').classList.remove('scrolling');
    }
  }

  function handleResize () {
    scrollOffset = $('.content-header').outerHeight() - $('header.header').outerHeight();
  }

  $('.q-title').click(function(){
    $(this).parent().toggleClass('open');
  })

  $('.vid-block').fitVids();



});